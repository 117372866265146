<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <layout width="420">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Item name"
            rules="required"
          >
            <v-text-field
              v-model="state.name"
              label="Item name"
              :error-messages="errors"
            >
              {{ state.name }}
            </v-text-field>
          </validation-provider>
          <v-text-field v-model="state.description" label="Description">
            {{ state.description }}
          </v-text-field>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="invalid"
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { managementItemService } from '@/modules/object-types/api';
import { schemasService } from '@/modules/common/api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'ManagementItemEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    parentSchemaId: {
      type: String,
      default: ''
    },
    managementItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const form = ref(null);
    const managementItem = ref({});
    const state = ref({
      name: '',
      description: ''
    });

    const edit = computed(() => props.managementItemId);
    const title = computed(() =>
      edit.value ? 'Edit management item' : 'Add management item'
    );
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;
      let createdManagementItemId = props.managementItemId;
      if (!edit.value) {
        const schemaId = await managementItemService.fetchBaseManagementItemSchemaId();
        createdManagementItemId = await managementItemService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId,
          parentSchemaId: props.parentSchemaId
        });
        managementItem.value = await managementItemService.fetch(
          createdManagementItemId
        );
      } else {
        await managementItemService.update(props.managementItemId, {
          name: state.value.name,
          description: state.value.description
        });
        // edit new state
      }
      await schemasService.updateValues(
        [
          {
            id: managementItem.value.infoName.id,
            value: state.value.name
          }
        ],
        createdManagementItemId
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (edit.value) {
        managementItem.value = await managementItemService.fetch(
          props.managementItemId
        );
        state.value = {
          name: managementItem.value.infoName.value,
          description: managementItem.value.description || ''
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      form
    };
  }
};
</script>
